<template>
  <footer ref="sectionRef" class="px-page bg-purple-700 pb-[26px] pt-16 text-white md:pb-10px">
    <div class="grid-default lg:min-h-[480px]">
      <div class="col-span-12 md:col-span-4 lg:col-span-6">
        <div class="flex min-h-full flex-col gap-y-14 md:justify-between">
          <RegionSelect
            class="md:relative md:top-[-4px] md:mb-12 lg:-top-1"
            :isVisible="isSectionVisible"
            @click="showChangeRegion = true"
          />
          <div class="max-w-sm md:max-w-[330px]">
            <div class="mb-16 min-h-[226px]">
              <Transition name="fade">
                <LazyNewsletterSubscribe
                  v-if="isSectionVisible"
                  :title="$t('newsletterSubscribe.title')"
                  :style="'purple'"
                />
              </Transition>
            </div>
            <Social class="my-16 md:mb-10 md:flex" />
          </div>
        </div>
      </div>
      <div class="col-span-12 md:col-span-8 md:col-start-5 lg:col-span-6">
        <Sitemap class="mb-14" :data="data.allSitemapItems" />
      </div>
    </div>
    <hr class="mb-8 h-px border-none bg-white bg-opacity-30 md:my-8px md:block" />
    <div class="grid-default">
      <div class="col-span-12 md:col-span-5 lg:col-span-6">
        <div class="h-full md:flex md:flex-col md:justify-between">
          <FooterLinks :data="data.allFooterLinks" />
          <div>
            <!-- this -->
            <LazyFooterAwards v-if="config.public.country !== 'CH' && isSectionVisible" />
            <small
              class="mb-4 block text-[15px] font-medium leading-[1.5rem] opacity-70 md:mb-0 md:pr-4 md:leading-[160%]"
              >© Copyright {{ year }} {{ $t('footer.copyright') }}</small
            >
          </div>
        </div>
      </div>
      <div class="col-span-12 md:col-span-7 md:flex md:flex-col md:justify-end lg:col-span-6">
        <div
          class="flex flex-col gap-[18px] text-justify text-[13px] tracking-[0.02em] opacity-70 md:gap-4px md:leading-[160%] md:tracking-normal"
          v-html="$t('footer.disclaimer', { year: new Date().getFullYear() })"
        ></div>
      </div>
    </div>
    <LazyChangeRegionModal
      v-if="isSectionVisible"
      class="text-black"
      :show="showChangeRegion"
      @on-close="showChangeRegion = false"
    />
  </footer>
</template>

<script setup lang="ts">
const config = useRuntimeConfig()

defineProps({
  data: {
    type: Object,
    default: () => {}
  }
})

const sectionRef = ref<HTMLElement | null>(null)
const isSectionVisible = ref(false)
const showChangeRegion = ref(false)
const year = new Date().getFullYear()

const { stop } = useIntersectionObserver(sectionRef, ([{ isIntersecting }], _observerElement) => {
  if (isIntersecting) {
    isSectionVisible.value = true
  }
})

onUnmounted(() => {
  stop()
})
</script>
